import 'reflect-metadata';
import {container, injectable} from 'tsyringe';
import {store} from '@/store';


@injectable()
export class FeedbackAnswerSandbox {
    private store = store;

    constructor() {
    }

    getFeedbackId(payload: any): any {
        this.store.dispatch('getFeedbackId', payload);
    }
}

import 'reflect-metadata';
import {Options, Vue} from 'vue-class-component';
import FileViewComponent from '@/core/shared/file-view/file-view.component.vue';

@Options({
    name: 'answer-form-component',
    components: {
        FileViewComponent
    },
    props: {
        feedback: {
            type: Object,
            require: true,
        },
        statuses: {
            type: Array,
            require: true,
        }

    }

})
export default class AnswerFormComponent extends Vue {


}

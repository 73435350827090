import 'reflect-metadata';
import {Options, Vue} from 'vue-class-component';

@Options({
    name: 'file-view-component',
    props: {
        fileSelect: {
            type: Object,
            require: false
        },
        fileDownload: {
            type: Object,
            require: false
        }
    }

})

export default class FileViewComponent extends Vue {

    public deleteFile(file: any) {
        this.$emit('deleteFile', file);
    }
}

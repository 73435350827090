<style src="./file-view.component.scss" lang="scss"></style>

<template>
  <div class="file-view">
    <div class="file-view__wrapper" v-if="fileDownload">
      <a :href="fileDownload?.link" download target="_blank">
        <img src="@/assets/images/icons/file.svg" alt="">
        <span>{{ fileDownload?.name }}</span>
        <img src="@/assets/images/icons/download-file.svg" alt="">
      </a>
    </div>
    <div class="file-view__wrapper" v-else>
      <img src="@/assets/images/icons/file.svg" alt="">
      <div>
        <template v-if="fileSelect?.name?.length<=10">
          {{ fileSelect.name }}
        </template>
        <template v-else>
          {{ fileSelect?.name?.slice(0, 10) }}...
        </template>
      </div>
      <img class="ml-15 delete" @click="deleteFile(fileSelect)" src="@/assets/images/icons/delete-file.svg" alt="">
    </div>
  </div>
</template>

<script src="./file-view.component.ts"></script>

import 'reflect-metadata';
import {Options, Vue} from 'vue-class-component';
import {container} from 'tsyringe';
import {store} from '@/store';
import AnswerFormComponent from '@/pages/feedback-answer/components/answer-form/answer-form.component.vue';
import {FeedbackAnswerSandbox} from '@/pages/feedback-answer/sandbox/feedback-answer.sandbox';

@Options({
    name: 'feedback-answer-component',
    components: {
        AnswerFormComponent,
    },

})
export default class FeedbackAnswerComponent extends Vue {
    private sandbox: FeedbackAnswerSandbox = container.resolve(FeedbackAnswerSandbox);
    private store = store;

    public mounted() {
        this.sandbox.getFeedbackId(this.$route.params.id);
    }

    get feedback() {
        return this.store.getters._getFeedback;
    }
}
